import * as React from "react";
import ClassBanner from "../../components/classes/ClassBanner";
import Container from "../../components/layout/Container";
import Layout from "../../components/shared/layout";
import { useFetch } from "../../services/classes";
import { useEffect } from "react";
import api from "../../services/api";
import { useState } from "react";

const PostById = ({ params }) => {
	const id = params["id"];
	const [post, setPost] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getPostDetails();
	}, []);

	const getPostDetails = () => {
		api.get(`posts/${id}`).then((result) => {
			setPost(result.data);
			setLoading(false);
		});
	};


	return (
		<Layout
			loading={loading}
			seoTitle={loading ? "loading..." : post?.title}
			allowMobile={false}
		>
			<ClassBanner cover={post?.cover?.url} name={post?.title} coverOnly />
			<Container maxWidth="max-w-2xl py-[100px]">
				{post?.content?.split("\n").map((item, key) => {
					return (
						<p key={key}>
							{item}
							{"\n"}
						</p>
					);
				})}
			</Container>
		</Layout>
	);
};

export default PostById;
