import "../../styles/class-banner.css";
import React from "react";
import { Colors } from "../../constants/colors";
import Button from "../ui/Button";

export default function ClassBanner({
	cover,
	name,
	nameFont,
	objective,
	classData,
	play,
	coverOnly,
}) {
	return (
		<div
			className="classBanner flex justify-end align-bottom flex-col h-[79vh]"
			style={{ backgroundImage: `url(${cover})` }}
		>
			<div className="mb-10 z-1 relative">
				{/* {nameFont ? <img src={nameFont?.url} style={{width: 400, maxWidth: '80%', margin: '0 auto'}}  /> : <div className="text-center text-5xl ">{name}</div>} */}
				<div className="text-center font-hn-ex font-bold text-7xl max-w-2xl mx-auto">
					{name}
				</div>
				<div className="classBanner-objective text-center text-xl mt-10">
					{objective}
				</div>
				{!coverOnly && (
					<div className="text-center mt-16 flex flex-row align-middle justify-center">
						<Button
							label="Start Lesson"
							size="xlarge"
							startIcon="PlayIcon"
							uppercase
						/>
						{classData?.trailer && (
							<Button
								label="Preview"
								size="xlarge"
								color="secondary"
								style={{ marginLeft: 20 }}
								onClick={() =>
									play(
										{
											url: classData?.trailer?.url,
											title: `Trailer: ${classData?.name}`,
										},
										true
									)
								}
								uppercase
							/>
						)}
					</div>
				)}
			</div>
		</div>
	);
}
